/* eslint-disable  react/prop-types */
import React from 'react'
import Carousel from 'react-multi-carousel'

import './styles.scss'

const CAROUSEL_RESPONSIVE_RULES = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 1,
    partialVisibilityGutter: 460,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 1,
    partialVisibilityGutter: 200,
  },
}

const CarouselItem = ({item}) => {
  const  {image, company, request, itemClass}= item
  return (
    <div className="item-content">
      <div
        className={`slide-img ${itemClass}`}
        style={image ? { backgroundImage: `url(${image?.sourceUrl})` } : {}}
      />
      <div className="additional-info">
        <div>
          <div className="title">Client</div>
          <div className="content">{company?.name}</div>
        </div>
        <div>
          <div className="title">Demande</div>
          <div className="content">{request?.name}</div>
        </div>
      </div>
    </div>
  )
}

const Slider = ({ data, filter, selectedItem }) => {
  console.log(selectedItem)
  return (
    <Carousel
      additionalTransform={0}
      arrows
      partialVisible
      containerClass="realization-slider"
      itemClass="custom-item-slide"
      infinite
      responsive={CAROUSEL_RESPONSIVE_RULES}
      swipeable
    >
      {selectedItem &&
        <CarouselItem item={selectedItem}/>}
      {
        data?.map(({ acf }) =>
          acf?.realizations?.map((item,index) => 
            {
              const { image, company, request, className } = item
              if((!filter || filter === request.slug)&&( !!image?.sourceUrl &&
              selectedItem.image?.sourceUrl !== image?.sourceUrl)){
              return <CarouselItem key={index} item={item}/>}
              return false
            }))}
  </Carousel>
  )
}

export default Slider
 