import React from 'react'
import { graphql } from 'gatsby'

import propTypes from 'prop-types'
import Layout from '../layouts/default'
import Banner from '../components/sections/_banner/default'
import Services from '../components/sections/services'
import OfferBlock from '../components/sections/offerBlock'
import RealizationGallery from '../components/sections/realizationGallery'

const RealizationsTemplate = ({
  data: {
    wpPage: { template:{acfRealizations:acf}, seo, title },
  },
}) => {
  return (
    <Layout seo={seo}>
      <Banner
        img="default"
        title={acf.headerTitle ? acf.headerTitle : title}
        backgroundImg={
          acf.headerPicture ? acf.headerPicture?.sourceUrl : ''
        }
      />
      <RealizationGallery />
      {parseInt(acf.displayServices, 10) === 1 && acf.services && (
        <Services services={acf.services} />
      )}
      {acf.offerBlockTitle && acf.offerBlockDescription && (
        <OfferBlock
          title={acf.offerBlockTitle}
          description={acf.offerBlockDescription}
          cta={acf.offerBlockCta}
          picture={acf.offerBlockImageFile}
        />
      )}
      {parseInt(acf.displayServices2, 10) === 1 && acf.servicesBlock2 && (
        <Services services={acf.servicesBlock2} />
      )}
    </Layout>
  )
}

RealizationsTemplate.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
}
export default RealizationsTemplate

export const query = graphql`
  query RealizationsPage($id: String!) {
    wpPage(id: { eq: $id }) {
      template {
        templateName
        ... on WpTemplate_Realizations {
          acfRealizations {
            displayServices
            displayServices2
            headerPicture {
              sourceUrl
            }
            headerTitle
            offerBlockCta {
              url
              title
            }
            offerBlockDescription
            offerBlockImageFile {
              sourceUrl
            }
            offerBlockTitle
            services {
              ...serviceFragment
            }
            servicesBlock2 {
              ...serviceFragment
            }
          }
        }
      }
      title

      ...yoastMeta
    }
  }
`
