/* eslint-disable  react/prop-types */
import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Modal } from 'react-bootstrap'
import Masonry from 'react-masonry-component'

import './styles.scss'

import Slider from './slider'

const masonryOptions = {
  transitionDuration: '0.8s',
  itemSelector: '.grid-item',
  percentPosition: true,
  columnWidth: 1,
}

const RealizationGallery = () => {
  const { allWpRealizationCategory, allWpRealization } = useStaticQuery(graphql`
    query requestsAndRealizations {
      allWpRealization {
        nodes {
          ...realizationFragment
        }
      }
      allWpRealizationCategory {
        nodes {
          slug
          name
        }
      }
    }
  `)
  const data = allWpRealization.nodes

  const requests = allWpRealizationCategory.nodes

  const [filter, setFilter] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(false)

  return (
    <Container className="realization-gallery">
      <div className="text-center filter-group">
        <ul className="requests-list">
          <li
            onClick={() => setFilter(false)}
            className={filter === false ? 'active' : ''}
          >
            Tout
          </li>
          {requests &&
            requests.map(({ name, slug }) => (
              <li
                key={slug}
                onClick={() => setFilter(slug)}
                className={filter === slug ? 'active' : ''}
              >
                {name}
              </li>
            ))}
        </ul>
      </div>
      <Masonry
        className="gallery-list"
        elementType="ul"
        options={masonryOptions}
      >
        {data &&
          data.map(({ acf }) =>
            acf?.realizations.map(
              ({ image, request, className, hideMobile }, index) => {
                return (
                  (!filter || filter === request?.slug) && (
                    <li
                      key={`realizations-${index}`}
                      className={`grid-item ${
                        request?.slug ?? ''
                      } ${className} ${hideMobile ? 'd-none d-md-block' : ''}`}
                      style={
                        image
                          ? { backgroundImage: `url(${image?.sourceUrl})` }
                          : null
                      }
                      onClick={() => {
                        setShowModal(true)
                        setSelectedItem(acf.realizations[index])
                      }}
                    >
                      <div className="overlay">
                        <span className="icon-zoom" />
                      </div>
                    </li>
                  )
                )
              }
            )
          )}
      </Masonry>
      <Modal
        animation
        className="modal-slider"
        centered
        size="xl"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header>
          <button
            onClick={() => setShowModal(false)}
            type="button"
            className="close"
          >
            <span className="icon-close" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Slider data={data} filter={filter} selectedItem={selectedItem} />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default RealizationGallery
