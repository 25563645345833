/* eslint-disable react/prop-types, camelcase, react/no-danger */
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import Button from '../../app-components/button'
import './styles.scss'

import yellowBg from '../../../assets/images/slider-yellow-back.svg'

const OfferBlock = ({ title, description, cta, picture }) => (
  <div className="offer-block">
    <Container
      fluid
      className="p-0 pr-4 pr-lg-0 position-relative overflow-hidden"
    >
      <Row>
        <Col
          md={1}
          className="p-0 d-none d-sm-none d-md-none d-lg-flex align-items-center col-md-1"
        >
          <img className="m-0" src={yellowBg} alt={yellowBg} />
        </Col>
        <Col
          className="right-content py-4"
          md={{ span: 11, offset: 1 }}
          lg={{ span: 11, offset: 0 }}
        >
          <Row className="align-items-center">
            <Col
              className="text-wrapper"
              xs={12}
              md={{ span: 10, offset: 1 }}
              lg={6}
            >
              <h2>{title}</h2>
              <div
                className="offer-text"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              {cta && (
                <Button
                  className="text-center"
                  name={cta.title}
                  href={cta.url}
                />
              )}
            </Col>
            {picture && (
              <Col
                lg={{ span: 4, offset: 1 }}
                className="d-none d-lg-block pl-0 text-right"
              >
                <img
                  className="m-0 img-fluid"
                  src={picture.sourceUrl}
                  alt={picture.title}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
)

export default OfferBlock
